import React, { useState, FunctionComponent, ReactNode } from 'react';

type AccordionProps = {
  title: string;
  children?: ReactNode;
};

const Accordion: FunctionComponent<AccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mx-auto w-full max-w-4xl mb-2 last:mb-0 overflow-hidden rounded-lg shadow-md bg-white" style={{ borderColor: '#001369', borderWidth: '1px' }}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center w-full px-4 py-3 text-left focus:outline-none sm:px-6 sm:py-4 bg-white hover:bg-gray-100 border-b"
        style={{ borderColor: '#001369' }}
      >
        <span className={`text-xl font-semibold mr-4 transition-transform duration-300 transform ${isOpen ? 'rotate-180' : ''}`} style={{ color: '#001369' }}>
          {isOpen ? '−' : '+'}
        </span>
        <span className="flex-grow text-lg sm:text-xl" style={{ color: '#001369' }}>{title}</span>
      </button>
      <div className={`${isOpen ? 'block' : 'hidden'} px-4 py-3 text-gray-800 sm:px-6 sm:py-4`}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;

