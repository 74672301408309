import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '../lib/utils';
import logo from  '../assets/logoNew.png';

export function useActiveLink() {
  const [activeLink, setActiveLink] = useState('');
  const setLink = async (link :string) : Promise<void> => {
    await setActiveLink(link);
    if (link.includes('refresh')) {
      window.location.reload();
    }
  };
  return {activeLink, setLink};
}

function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {activeLink, setLink} = useActiveLink();
    const location = useLocation();
    const handleLinkClick = (link : string) => {
      setLink(link);
      setMenuOpen(false);
    };
    useEffect(() => {
      // Función para manejar el evento de cambio de tamaño de pantalla
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      const activeLinkForLink = location.pathname.split('/')[1];
      if(activeLinkForLink){
        setLink(activeLinkForLink);
      }
      // Agregar el evento de cambio de tamaño de pantalla cuando el componente se monta
      window.addEventListener('resize', handleResize);
      // Eliminar el evento cuando el componente se desmonta para evitar pérdidas de memoria
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };
  return (
    <header className="fixed w-full left-0 top-0 z-50">
      <div className={cn('container mx-auto flex items-center justify-between p-5 lg:py-5 lg:px-0')}>
          <section>
            <Link to='/home' onClick={() => handleLinkClick('home')} className={cn('h-[50px] uppercase font-black text-white flex items-center text-xl')} >
              <img className={cn('h-[250px]')} src={logo}></img> 
            </Link>
            </section>
          <section>
            <ul className={cn('lg:flex items-center gap-x-8 mr-4', windowWidth <= 1025 && 'hidden')}>
              <li><Link to='/home' onClick={() => handleLinkClick('home')} className={cn('relative text-[var(--text-color)] transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-[var(--text-color)] before:rounded-full before:transition-all before:duration-300', activeLink === 'home' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Inicio</Link></li>
              <li><Link to='/about' onClick={() => handleLinkClick('about')} className={cn('relative text-[var(--text-color)] transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-[var(--text-color)] before:rounded-full before:transition-all before:duration-300', activeLink === 'about' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Nosotros</Link></li>
              <li><Link to='/services' onClick={() => handleLinkClick('services')} className={cn('relative text-[var(--text-color)] transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-[var(--text-color)] before:rounded-full before:transition-all before:duration-300', activeLink === 'services' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Servicios</Link></li>
              <li><Link to='/legal' onClick={() => handleLinkClick('legal')} className={cn('relative text-[var(--text-color)] transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-[var(--text-color)] before:rounded-full before:transition-all before:duration-300', activeLink === 'legal' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Marco legal</Link></li>
              <li><Link to='/contactUs' onClick={() => handleLinkClick('contactUs')} className={cn('relative text-[var(--text-color)] transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-[var(--text-color)] before:rounded-full before:transition-all before:duration-300', activeLink === 'contactUs' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Contacto</Link></li>
            </ul>
            <button onClick={toggleMenu} id='menu-icon' className='relative lg:hidden buttomMenu'>
              <span className='absolute right-0 -top-4 w-8 h-0.5 bg-[var(--text-color)]'></span>
              <span className='absolute right-0 -top-2 w-8 h-0.5 bg-[var(--text-color)]'></span>
              <span className='absolute right-0 -top-0 w-8 h-0.5 bg-[var(--text-color)]'></span>
            </button>
          </section>
      </div>
      <ul className={cn('lg:flex items-center gap-x-8 navbar', isMenuOpen && 'open', windowWidth > 1025 && 'hidden' )}>
          <li><Link to='/home' onClick={() => handleLinkClick('home')} className={cn('relative text-white transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-white before:rounded-full before:transition-all before:duration-300', activeLink === 'home' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Inicio</Link></li>
          <li><Link to='/about' onClick={() => handleLinkClick('about')} className={cn('relative text-white transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-white before:rounded-full before:transition-all before:duration-300', activeLink === 'about' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Nosotros</Link></li>
          <li><Link to='/services' onClick={() => handleLinkClick('services')} className={cn('relative text-white transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-white before:rounded-full before:transition-all before:duration-300', activeLink === 'services' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Servicios</Link></li>
          <li><Link to='/legal' onClick={() => handleLinkClick('legal')} className={cn('relative text-white transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-white before:rounded-full before:transition-all before:duration-300', activeLink === 'legal' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Marco legal</Link></li>
          <li><Link to='/contactUs' onClick={() => handleLinkClick('contactUs')} className={cn('relative text-white transition-colors duration-300 before:absolute before:left-0 before:-bottom-2 before:h-0.5 before:bg-white before:rounded-full before:transition-all before:duration-300', activeLink === 'contactUs' ? 'before:w-full' : 'before:w-0 hover:before:w-full')}>Contacto</Link></li>
      </ul>
{/*       

      
      <div className='main'>
          <div className='ri-menu-line' onClick={toggleMenu} id='menu-icon'></div>
      </div> */}
  </header>
  );
}

export default Header;