import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';
import './slider-item.css'
import { useActiveLink } from '../../header/Header';

interface SliderItemProps {
    itemActive: number;
    id: number;
    image: string;
    subTitle: string;
    title: string;
    desc: string;
    buttonLabel: string;
    buttonNavigate: string;
}
export const SliderItem = ({itemActive, id, subTitle, title, image, desc, buttonLabel, buttonNavigate}: SliderItemProps) => {
    const { setLink } = useActiveLink();
    const handleLinkClick = (link : string) => {
      setLink(link + '/refresh');
    };
    return (<li className={cn('absolute inset-0 overflow-hidden after:absolute after:w-full after:h-full after:left-0 after:bottom-0 after:bg-slider transition-all duration-500', itemActive == id ? 'opacity-100 z-10': 'opacity-0')}>
        <div className="relative w-full h-full">
            <img src={image} alt="Image" className='object-cover image h-full'></img>
        </div>
        <div className="space-y-4 absolute left-[10%] top-[20%] w-[500px] max-w-[80%] z-10">
            <p className={cn("uppercase tracking-[10px] text-color-custom translate-y-[30px] blur-[20px] opacity-0", itemActive === id && 'animate-show-content')}>{subTitle}</p>
            <h2 className={cn("text-6xl smallxx sm:text-6xl lg:text-8xl m-0 text-color-custom font-bold translate-y-[30px] blur-[20px] opacity-0 animation-delay-4", itemActive === id && 'animate-show-content')}>{title}</h2>
            <p className={cn('text-200 text-color-custom translate-y-[30px] blur-[20px] opacity-0 animation-delay-5',  itemActive === id && 'animate-show-content')}>{desc}</p>
            <button onClick={() => handleLinkClick(buttonNavigate)} className={cn('text-200 text-color-custom translate-y-[30px] blur-[20px] opacity-0 animation-delay-5',  itemActive === id && 'animate-show-content')}> <Link to={buttonNavigate} >{buttonLabel}</Link></button>
        </div>
    </li>);
}