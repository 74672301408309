import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './header/Header';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './publicPage/home/Home';
import About from './publicPage/about/About';
import Services from './publicPage/services/Services';
import Legal from './publicPage/legal/Legal';
import News from './publicPage/news/News';
import ContactUs from './publicPage/contactUs/ContactUs';
import { NextUIProvider } from '@nextui-org/react';
import { cn } from './lib/utils';
import WhatsAppButton from './general/components/whatsapp';
import Footer from './footer/footer';

function App() {
  return (
    <>
    <NextUIProvider>
      <Router>
          <WhatsAppButton phoneNumber='59898945569' message='¡Hola! Me gustaria saber mas acerca de tus servicios' />
          <Header/>

          <main>
              <Routes>
                  <Route path="/" element={<Navigate to="/home" replace />} />
                  <Route path="/home" Component={() => <Home id="home" />} />
                  <Route path="/about" Component={() => <About id="about" />} />
                  <Route path="/services" Component={() => <Services id="services" />} />
                  <Route path="/legal" Component={() => <Legal id="legal" />} />
                  <Route path="/contactUs" Component={() => <ContactUs id="contactUs" />} />
                  <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
          </main>

          <Footer></Footer>
        </Router>
    </NextUIProvider>
    </>
  );
}

export default App;
