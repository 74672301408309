import React from 'react';
import './ContactUs.css';
import { useState } from 'react';
import ContactUsForm from './form/contactUsForm';
import {Map} from './Map';
import {ContactInfo} from './ContactInfo';
import { cn } from '../../lib/utils';
import dalle1 from '../../assets/dalle1.webp'
import a1 from '../../assets/a1.webp'

interface ContactUsProps {
    id: string;
  }

const ContactUs: React.FC<ContactUsProps> = ({ id }) => {
  return (
    <><div className={cn('contactTotal relative')}>
      <li className={cn('absolute inset-0 bannerHeight overflow-hidden after:absolute after:w-full after:h-full after:left-0 after:bottom-0 after:bg-secundarySectionHome transition-all duration-500 opacity-100 z-10')}>
        <div className="relative w-full h-full">
          <img src={dalle1} alt="Image" className='object-cover image h-full'></img>
        </div>
        <div className="space-y-4 absolute left-[10%] right-[10%] top-[30%] w-[100%] max-w-[80%] z-10 margin-custom">
          <p className={cn("uppercase tracking-[10px] text-center text-color-custom translate-y-[30px] blur-[20px] opacity-0 animate-show-content")}>ASESORAMIENTO</p>
          <h2 className={cn("text-6xl lg:text-5xl m-0 text-center text-color-custom font-bold translate-y-[30px] blur-[20px] opacity-0 animation-delay-4 animate-show-content")}>Contacto</h2>
          <p className={cn('text-200 text-color-custom text-center translate-y-[30px] blur-[20px] opacity-0 animation-delay-5 animate-show-content')}>Para explorar más acerca de nuestros servicios o recibir orientación en temas relacionados con la seguridad en el trabajo y bienestar ocupacional, siéntase libre de comunicarse con nuestro equipo.</p>
        </div>
      </li>
      <li className={cn('relative contactInfo overflow-hidden top-[30%] after:absolute after:w-full after:h-full after:left-0 after:bottom-0 transition-all duration-500 opacity-100 z-10')}>
        <div className={cn("right-[10%] top-[5%] w-[500px] max-w-[80%] max-h-[80%] absolute z-10 grid grid-cols-1 md:grid-cols-1 contactContainer")}>
            <ContactInfo />
            {/* <Map /> */}
        </div>
        <div className={cn("margin-top-custom right-[10%]  w-[500px] max-w-[80%] max-h-[80%] absolute z-10 grid grid-cols-1 md:grid-cols-1 contactContainer")}>
            <ContactUsForm></ContactUsForm>
        </div>
      </li>
    </div>
    </>
  );
}

export default ContactUs;
