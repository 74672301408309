import React from 'react';

const Valor = ({ titulo, descripcion, icono }) => {
    return (
      // Ajustamos el contenedor principal para centrar el contenido y limitar su ancho.
      <div className="flex items-center space-x-4 border-b border-gray-300 py-4 justify-center">
        <div className="flex items-start space-x-4">
          <div className="rounded-full bg-blue-900 p-4 flex-shrink-0">
            <i className={`${icono} text-white text-3xl`}></i>
          </div>
          <div className="flex flex-col max-w-md"> {/* Limitamos el ancho máximo del texto */}
            <h3 className="font-semibold text-xl style={{ color: '#001369' }}">{titulo}</h3>
            <p className="text-gray-600">{descripcion}</p>
          </div>
        </div>
      </div>
    );
};

const Valores = () => {
  return (
    <div className="bg-white py-8">
      <h2 className="text-3xl font-bold style={{ color: '#001369' }} mb-8 text-center">NUESTROS VALORES</h2>
      <div className="flex flex-col items-center">
        {/* Envuelto cada Valor en un contenedor para alinearlos al centro */}
        <Valor
          titulo="COMPROMISO"
          descripcion="Nos comprometemos firmemente con nuestras metas y promesas, generando confianza a través de la dedicación. Este enfoque nos permite alcanzar la excelencia en nuestros proyectos."
          icono="fas fa-handshake"
        />
        <Valor
          titulo="HONESTIDAD"
          descripcion="La honestidad guía nuestras acciones, promoviendo relaciones de confianza y transparencia. Es fundamental en nuestra toma de decisiones, asegurando una conducta ética."
          icono="fas fa-balance-scale"
        />
        <Valor
          titulo="CREATIVIDAD"
          descripcion="Fomentamos la creatividad para idear soluciones innovadoras, destacando en el mercado. Nuestra cultura alienta la experimentación y la exploración de nuevas ideas."
          icono="fas fa-lightbulb"
        />
        <Valor
          titulo="RESILIENCIA"
          descripcion="Afrontamos desafíos con resiliencia, aprendiendo de cada experiencia. Esta capacidad nos fortalece, permitiéndonos prosperar y crecer continuamente."
          icono="fas fa-leaf"
        />
        <Valor
          titulo="AGILIDAD"
          descripcion="La agilidad nos permite adaptarnos y responder rápidamente a cambios y desafíos, implementando soluciones efectivas y manteniéndonos a la vanguardia."
          icono="fas fa-running"
        />
        
        {/* ... */}
      </div>
    </div>
  );
};

export default Valores;
