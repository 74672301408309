import React from 'react';
import './About.css';
import { cn } from '../../lib/utils';
import nosotrosimg from '../../assets/nosotros.webp';
import ContactUsForm from '../contactUs/form/contactUsForm';
import AboutUs from './AboutUs';
import ProfileCard from './ProfileCard';
import Valores from './valores';
import ClientSlider from '../../general/components/clientSlider';

interface AboutProps {
  id: string;
}

const About: React.FC<AboutProps> = ({ id }) => {
  return (
    <>
      <div className="relative mt-30 h-[60vh] overflow-hidden after:absolute after:w-full after:h-full after:left-0 after:bottom-0 after:bg-secundarySectionHome transition-all duration-500 opacity-100 z-10">
        <img src={nosotrosimg} alt="Background" className="object-cover w-full h-full"></img>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-x-5 md:left-[10%] md:right-[10%] top-[25%] md:top-[30%] w-auto md:w-[80%] z-10 text-center  margin-custom">
          <p className={cn("uppercase tracking-[10px] text-center text-color-custom translate-y-[30px] blur-[20px] opacity-0 animate-show-content")}>QUIÉNES SOMOS</p>
          <h2 className={cn("text-4xl md:text-5xl lg:text-6xl  text-center mt-2 text-color-custom font-bold translate-y-2 md:translate-y-[30px] blur-sm md:blur-[20px] opacity-0 animation-delay-4 animate-show-content")}>Nosotros</h2>
          <p className={cn('text-xs mt-5 md:text-sm lg:text-base text-color-custom text-center translate-y-2 md:translate-y-[30px] blur-sm md:blur-[20px] opacity-0 animation-delay-5 animate-show-content')}>Somos una organización dedicada a ofrecer consultoría especializada en Seguridad y Salud en el Trabajo, respondiendo a las recientes demandas normativas tanto a nivel local como global. Nuestro compromiso es asegurar entornos laborales seguros y saludables, cumpliendo con los más altos estándares de calidad y regulaciones vigentes.</p>
        </div>
      </div>

      <div className="container mx-auto px-4 my-12 flex flex-wrap items-stretch justify-center">
        <div className="md:flex md:w-3/4 lg:w-5/6 xl:w-4/5 2xl:w-3/4 justify-between space-x-4">
          <div className="flex-1 px-4 mb-8 md:mb-0">
            <AboutUs />
          </div>
          <div className="flex-1 px-4 md:mt-12"> {/* Ajuste aplicado aquí */}
            <ProfileCard />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 my-12">
        <Valores />
      </div>

      <div className='container mx-auto px-4 my-12'>
        
      <ClientSlider></ClientSlider>
      </div>
      

      <div className="container mx-auto px-4 my-12">
        <ContactUsForm />
      </div>
    </>
  );
}

export default About;
