import React from 'react';
import './About.css'
const AboutUs: React.FC = () => {
  return (
        <div className="p-10">
      <h2 className="text-3xl style={{ color: '#001369' }} font-bold mb-4">NOSOTROS</h2>
      <p className="text-base  text-gray-800 mb-4">
        En SeguTra, nos enfocamos en entender las necesidades específicas de cada cliente y desarrollar soluciones a medida que cumplan con los estándares de seguridad laboral.
      </p>
      <p className="text-base text-gray-800 mb-4">
        Nuestro objetivo es prevenir riesgos y superar las expectativas de los clientes, brindando asesoramiento técnico para mejorar las condiciones laborales y promover un desempeño seguro.
      </p>
      <p className="text-base text-gray-800 mb-4">
        Nos dedicamos a asistir en la prevención de riesgos para contribuir al cuidado de la seguridad y la salud laboral, aspirando a ser líderes regionales en consultoría y formación especializada.
      </p>
    </div>

  );
};

export default AboutUs;

