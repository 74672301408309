import React, { useState } from 'react';
import c1 from './../../assets/clients/c1.png'
import c2 from './../../assets/clients/c2.png'
import c3 from './../../assets/clients/c3.png'
import c5 from './../../assets/clients/c5.png'
import c6 from './../../assets/clients/c6.png'
import c7 from './../../assets/clients/c7.png'

const ClientSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const clients = [
    { name: '', image: c1 },
    { name: '', image: c2 },
    { name: '', image: c3 },
    { name: '', image: c6 },
    { name: '', image: c5 },
    { name: 'Cliente 7', image: c7 }, 
    // ... otros clientes
  ];


  return (
    <div className="flex flex-col w-full items-center justify-center">
      {/* Ajustamos el margen del título */}
      <h2 className="text-3xl font-bold text-center mb-4" style={{ color: '#001369' }}>PORTFOLIO DE CLIENTES DESTACADOS</h2>
      {/* Aumentamos el tamaño del subtítulo y ajustamos el margen para igualarlo con el espacio hacia los logos */}
      <p className="text-center text-gray-800 text-lg mb-4">Mira quienes confían en nosotros</p>
      <div className="flex justify-center items-center flex-wrap">
        {clients.map((client, index) => (
          <div key={index} className="p-2">
            <img
              src={client.image}
              alt={`Logo de ${client.name}`}
              className="object-contain h-32 w-32 md:h-40 md:w-40" // Mantenemos los tamaños de los logos
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientSlider;
