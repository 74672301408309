import React from 'react';

const Valor = ({ titulo, descripcion, icono, link }) => {
    return (
      <div className="min-h-[10rem] flex items-center space-x-4 border-b border-gray-300 py-4 justify-center">
        <div className="flex items-start space-x-4">
          <div className="rounded-full bg-blue-900 p-4 flex-shrink-0">
            <i className={`${icono} text-white text-3xl`}></i>
          </div>
          <div className="flex flex-col max-w-md"> {/* Limitamos el ancho máximo del texto */}
            <h3 className="font-semibold text-xl" style={{ color: '#001369' }}>{titulo}</h3>
            <p className="text-gray-600 mb-2">{descripcion}</p>
            {/* Enlace al decreto */}
            <a href={link} className="text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out" target="_blank" rel="noopener noreferrer">Ver Decreto</a>
          </div>
        </div>
      </div>
    );
};

const RamasActividad = () => {
  return (
    <div className="bg-white py-8">
      <h2 className="text-3xl font-bold text-[#001369] mb-8 text-center">Esferas de Operación</h2>
      <div className="flex flex-col items-center gap-4">
        <Valor
          titulo="Construcción"
          descripcion="Nuestros expertos en seguridad laboral se comprometen con dedicación y experiencia a garantizar la protección de los trabajadores en el sector de la construcción, velando por la seguridad en cada etapa de los proyectos."
          icono="fas fa-hammer"
          link="https://www.impo.com.uy/bases/decretos/125-2014" // Coloca aquí el enlace al decreto de Construcción
        />
        <Valor
          titulo="Industria, Comercio y Servicios"
          descripcion="Ofrecemos soluciones integrales de seguridad laboral para el sector industrial, comercial y de servicios, garantizando un entorno de trabajo seguro y protegido para cada empleado."
          icono="fas fa-industry"
          link="https://www.impo.com.uy/bases/decretos/406-1988" // Coloca aquí el enlace al decreto de Industria, Comercio y Servicios
          />
       
       <Valor
            titulo="Agro"
            descripcion="Nos especializamos en la implementación de medidas de seguridad laboral en el sector agrícola, promoviendo prácticas seguras y sostenibles para proteger a los trabajadores y preservar el medio ambiente."
            icono="fas fa-tractor"
            link="https://www.impo.com.uy/bases/decretos/321-2009" // Coloca aquí el enlace al decreto de Agro
            />
        <Valor
            titulo="Forestal"
            descripcion="Lideramos en la gestión de seguridad laboral en el sector forestal, asegurando condiciones seguras para los trabajadores y contribuyendo a la preservación de los recursos naturales."
            icono="fas fa-tree"
            link="https://www.impo.com.uy/bases/decretos/372-1999/1" // Coloca aquí el enlace al decreto de Forestal
            />
        <Valor
            titulo="Servicios Portuarios"
            descripcion="Garantizamos eficiencia y seguridad en la gestión portuaria, priorizando la seguridad laboral en todas nuestras operaciones para proteger a los trabajadores y promover un ambiente laboral seguro y productivo."
            icono="fas fa-ship"
            link="https://www.impo.com.uy/bases/decretos/394-2018/1" // Coloca aquí el enlace al decreto de Servicios Portuarios
            />
      </div>
    </div>
  );
};

export default RamasActividad;



