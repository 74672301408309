import React from 'react';
import './Services.css';
import { useState } from 'react';
import { cn } from '../../lib/utils';
import servicios1 from '../../assets/servicios1.webp'
import ServiceCard from './ServiceCard';
import Construccion from './Construccion';
import { ContactInfo } from '../contactUs/ContactInfo';

interface ServicesProps {
    id: string;
  }

const Services: React.FC<ServicesProps> = ({ id }) => {
  return (
    <>
      <div className="serviceContainerTotal relative">
        <li className="absolute inset-0 h-[60vh] overflow-hidden after:absolute after:w-full after:h-full after:left-0 after:bottom-0 after:bg-secundarySectionHome transition-all duration-500 opacity-100 z-10">
          <div className="relative w-full h-full">
            <img src={servicios1} alt="Image" className='object-cover image h-full'></img>
          </div>
          <div className="space-y-4 absolute left-[10%] right-[10%] top-[30%] w-[100%] max-w-[80%] z-10 margin-custom">
            <p className="uppercase tracking-[10px] text-center text-color-custom translate-y-[30px] blur-[20px] opacity-0 animate-show-content">INFORMACIÓN</p>
            <h2 className="text-4xl md:text-5xl lg:text-6xl m-0 text-center text-color-custom font-bold translate-y-2 md:translate-y-[20px] blur-sm md:blur-[20px] opacity-0 animation-delay-4 animate-show-content">SERVICIOS</h2>
            <p className='text-200 text-color-custom text-center translate-y-[30px] blur-[20px] opacity-0 animation-delay-5 animate-show-content'>Impulsamos la prevención de riesgos para la salud, ofreciendo una solución en seguridad laboral que es eficaz, eficiente y de confianza.</p>
          </div>
        </li>
      </div>

      <div className='container mx-auto px-4 sm:px-6 lg:px-8 my-8 sm:my-12 lg:my-12"'>
            <ServiceCard />
            
            {/* Inserta aquí el componente ConstructionServices */}
            <div>  
              <Construccion />
            </div>
            
            

            <div style={{marginTop: 100}}>
              <ContactInfo />
            </div>
      </div>
    </>
  );
}

export default Services;