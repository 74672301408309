import React from 'react';
import { Card, Button, CardBody } from '@nextui-org/react';
import background from '../../assets/servicios2.webp';


interface ServiceCardProps {
    title:string;
    description: string;
    icon: string;
    link: string;
}
const ServiceCard = ({ title, description, icon, link }: ServiceCardProps) => {
  return (
    <Card isHoverable className="m-4 transition duration-500 ease-in-out transform backdrop-filter backdrop-blur-xl bg-white/30 border border-gray-200 rounded-lg">
      <CardBody className="flex flex-col items-center p-10 text-black">
        <i className={`${icon} w-16 h-16 flex items-center justify-center bg-blue-900 text-white rounded-full p-4 text-3xl`} />
        <h2 className="text-xl text-center font-semibold mt-2 text-black">{title}</h2>
        <p className="text-gray-900 text-justify mt-5">{description}</p>
      </CardBody>
    </Card>
  );
};


function Services() {
  // Icons can be replaced with the actual icons you want to use
  const services : ServiceCardProps[] = [
    {
      title: 'ASESORÍA LEGAL APLICABLE',
      description: 'Nuestra asesoría legal en seguridad y salud ocupacional ofrece un servicio actualizado y personalizado, incluyendo la elaboración de matrices legales específicas para cada cliente y acceso a un compendio normativo. Nos aseguramos de que esté informado sobre las últimas leyes y decretos aplicables, facilitando el cumplimiento legal y la protección en su entorno laboral.',
      icon: 'fas fa-gavel',
      link: '#'
    },
    {
      title: 'SERVICIOS DE PREVENCIÓN Y SALUD EN EL TRABAJO. DECRETO 127/014',
      description: 'Bajo el marco del Decreto 127/014, ofrecemos servicios integrales de prevención y salud en el trabajo, centrados en la implementación de sistemas de gestión de seguridad y salud ocupacional (SYSO). Nuestro enfoque incluye la elaboración e implementación de planes de prevención de riesgos laborales, identificación y evaluación de riesgos, así como la creación de planes de capacitación en áreas clave como primeros auxilios, RCP y combate de incendios. Participamos activamente en la conformación de comisiones bipartitas de SYSO, y desarrollamos planes de emergencia y de gestión de residuos. Además, realizamos monitoreo constante de la salud de los trabajadores y llevamos a cabo auditorías SYSO para garantizar un entorno laboral seguro y saludable.',
      icon: 'fas fa-user-shield',
      link: '#'
    },
    {
      title: 'EVALUACIONES Y MEDICIONES DE AMBIENTES LABORALES',
      description: 'Nos especializamos en la realización de evaluaciones y mediciones de ambientes laborales para garantizar condiciones de trabajo óptimas y seguras. Nuestro servicio incluye evaluaciones higiénicas, abordando agentes físicos, químicos y biológicos, así como evaluaciones ergonómicas y de riesgo psicosocial, para prevenir problemas de salud relacionados con el puesto de trabajo. Realizamos mediciones y mapeos detallados de ruidos e iluminación, además de medir la concentración de contaminantes químicos. También evaluamos la ventilación, temperatura y humedad para asegurar un ambiente laboral confortable. Por último, ofrecemos espirometrías y audiometrías, completando así un amplio espectro de servicios para la evaluación de la salud ocupacional.',
      icon: 'fas fa-clipboard-list',
      link: '#'
    },
    {
      title: 'FORMACIÓN Y CAPACITACIÓN DE TRABAJADORES',
      description: 'Ofrecemos servicios de formación y capacitación integral para trabajadores, diseñando planes de capacitación a medida que abarcan desde primeros auxilios, RCP y uso de desfibriladores externos automáticos (DEA), hasta cursos de manejo defensivo. Nuestras capacitaciones están enfocadas en abordar riesgos específicos relacionados con el entorno laboral, incluyendo aquellos de índole químico, eléctrico y ergonómico, entre otros. Estos programas están diseñados para equipar a los trabajadores con las habilidades y conocimientos necesarios para actuar de manera segura y eficaz en sus respectivos puestos de trabajo, mejorando así la seguridad general y promoviendo un ambiente laboral saludable.',
      icon: 'fas fa-chalkboard-teacher',
      link: '#'
    },
    {
      title: 'COMBATE DE INCENDIOS. DECRETO 372/023',
      description: 'En el ámbito del combate de incendios, acorde al Decreto 372/023, proporcionamos un servicio completo que incluye desde la elaboración de proyectos contra incendio hasta la gestión de trámites ante la Dirección Nacional de Bomberos. Nos encargamos de la instalación de sistemas hidráulicos, redes de bocas de incendio, sistemas de espuma, agentes limpios, y sistemas de detección y alarma de incendio, asegurando una protección integral contra incendios en su establecimiento. Además, ofrecemos mantenimiento de equipos de bombeo y redes de incendios, así como de sistemas de detección y alarma contra incendio, garantizando su óptimo funcionamiento. Elaboramos planes de evacuación personalizados y brindamos formación y entrenamiento especializado a Brigadas de Emergencia para plantas industriales, equipando a su personal con las competencias necesarias para responder eficazmente ante emergencias.',
      icon: 'fas fa-fire-extinguisher',
      link: '#'
    },
    {
      title: 'GESTORIA EN HABILITACIONES',
      description: 'Ofrecemos un servicio integral de gestoría en habilitaciones, facilitando el proceso de obtención de permisos y cumplimiento normativo ante diversas entidades. Nuestro servicio incluye la gestión y tramitación de habilitaciones ante la Intendencia Municipal de Montevideo (IMM), así como ante el Sistema de Información de Monitoreo Empresarial (SIME), garantizando un soporte completo para su empresa. Además, nos encargamos de las gestiones necesarias para las habilitaciones de Bomberos, asegurando que su establecimiento cumpla con todas las normativas de seguridad vigentes. Extendemos nuestra expertise a la gestión de habilitaciones ante el Banco de Seguros del Estado (BSE), el Ministerio de Trabajo y Seguridad Social (MTSS), el Ministerio de Salud Pública (MSP), el Ministerio de Ambiente (MA) y el Laboratorio Tecnológico del Uruguay (LATU), cubriendo un amplio espectro de requerimientos normativos y legales. Con nuestro apoyo, su empresa podrá navegar eficientemente por el proceso de habilitaciones, asegurando el cumplimiento de todas las regulaciones pertinentes y facilitando el desarrollo fluido de sus operaciones.',
      icon: 'fas fa-handshake',
      link: '#'
    },    
    // ... other service cards
  ];

    return (
    <div className="relative rounded-lg overflow-hidden">
      <img src={background} className="absolute inset-0 w-full h-full object-cover opacity-75" alt="Background" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 relative p-6">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </div>
  );
}

export default Services;
