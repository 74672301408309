'use client';

import React from 'react';
import './Home.css';
import { useState } from 'react';
import { SliderItem } from '../../general/components/slider-item';
import imageT1 from './../../assets/t1.jpeg'
import imageT2 from './../../assets/nosotros.webp'
import imageT3 from './../../assets/LegalImg.webp'
import imageT4 from './../../assets/a1.webp'
import { Arrows } from '../../general/components/arrows';
import { SliderThumbnailItem } from '../../general/components/slider-thumbnail-item';
import {Button, Card, CardBody, Image, Slider} from "@nextui-org/react";
import { SecundarySection } from './SecundarySection/SecundarySection';
import { ContactInfo } from '../contactUs/ContactInfo';
import { Map } from '../contactUs/Map';
import { cn } from '../../lib/utils';
import ServiceCard from '../services/ServiceCard';
import ClientSlider from '../../general/components/clientSlider';
import ServiceSection from './ServiceSection/ServiceSection';
import RamasActividad from './RamasActividad';
interface HomeProps {
    id: string;
  }

const Home: React.FC<HomeProps> = ({ id }) => {
  const [itemActive, setItemActive] = useState<number>(1);
  const countItems = 4;

  const onPrev = () => {
    setItemActive(itemActive - 1);
    if(itemActive === 1){
      setItemActive(countItems);
    }
  }

  const onNext = () => {
    setItemActive(itemActive + 1);
    if(itemActive >= countItems){
      setItemActive(1);
    }
  }

  return (
    <>
    <div className='h-screen relative'>
      <ul>
        <SliderItem itemActive={itemActive} id={1} buttonLabel='Más información' buttonNavigate='/services' image={imageT1} subTitle='SERVICIOS' title='Prevención' desc='Las organizaciones eficientes se destacan por su capacidad para anticipar desafíos, Segutra les brinda apoyo para lograrlo.'></SliderItem>
        <SliderItem itemActive={itemActive} id={2} buttonLabel='Más información' buttonNavigate='/about' image={imageT2} subTitle='NOSOTROS' title='Nuestra empresa' desc='Somos un equipo multidisciplinario con mas de 5 años de experiencia en el rubro.'></SliderItem>
        <SliderItem itemActive={itemActive} id={3} buttonLabel='Más información' buttonNavigate='/legal' image={imageT3} subTitle='MARCO LEGAL' title='Responsabilidad' desc='Adquiera conocimiento sobre sus responsabilidades en el ámbito legal empresarial en relación con la prevención de salud y seguridad laboral, y desarrolle sus actividades con confianza y tranquilidad.'></SliderItem>
        <SliderItem itemActive={itemActive} id={4} buttonLabel='Más información' buttonNavigate='/contactUs' image={imageT4} subTitle='CONTACTO' title='Comuníquese' desc='Solicite una evaluación de costos detallada'></SliderItem>
      </ul>
      <Arrows onClickPrev={() => onPrev() } onClickNext={() => onNext() }></Arrows>
      <ul className='absolute bottom-0 z-10 flex justify-end md:justify-center gap-3 w-full h-[250px] px-4 md:px-14 overflow-y-hidden overflow-x-auto'>
        <SliderThumbnailItem itemActive={itemActive} image={imageT1} id={1} name='Prevención' onClick={() => setItemActive(1) } />
        <SliderThumbnailItem itemActive={itemActive} image={imageT2} id={2} name='Nuestra empresa' onClick={() => setItemActive(2) } />
        <SliderThumbnailItem itemActive={itemActive} image={imageT3} id={3} name='Responsabilidad' onClick={() => setItemActive(3) } />
        <SliderThumbnailItem itemActive={itemActive} image={imageT4} id={4} name='Comuníquese' onClick={() => setItemActive(4) } />
      </ul>
    </div>
    <div className='margin-secundarySection relative'>
      <SecundarySection></SecundarySection>
    </div>
    <div className="container mx-auto px-4 my-12">
        <RamasActividad/>
      </div>
      
    <div className='mt-32 min-h-[40vh] relative'>
    <div className="top-[20%] w-full max-w-full max-h-[80%] absolute z-10 grid grid-cols-1 md:grid-cols-1">
            <ClientSlider></ClientSlider>
        </div>
    </div>
    <div className='contactContainerHome relative'>
        <div className={cn("right-[10%] top-[20%] w-[500px] max-w-[80%] max-h-[80%] absolute z-10 grid grid-cols-1 md:grid-cols-1 contactContainer")}>
            <ContactInfo />
            {/* <Map /> */}
        </div>
    </div>
    </>
  );
}

export default Home;
