// ProfileCard.tsx
import React from 'react';


const ProfileCard: React.FC = () => {
  // URL actualizada del perfil de LinkedIn
  const linkedInUrl = "https://www.linkedin.com/in/segutra-prevencion-545a7a2b4/";
  const image = "https://media.licdn.com/dms/image/D4D03AQF4Kp_JIAhU0w/profile-displayphoto-shrink_800_800/0/1707939387390?e=1713398400&v=beta&t=CanRoQ7HAGkU2kgEWKqQV3DpKHlXnr-rBj5lyXuXt9k";

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-gray-200 p-6 flex flex-col justify-center items-center">
      <img className="w-32 h-32 rounded-full mx-auto" src={image} alt="Perfil de SeguTra" />
      <div className="text-center">
        <div className="font-bold text-xl mb-2">SeguTra</div>
        <p className="text-gray-700 text-base">
          Especialistas en Gestión de la Seguridad y Salud Laboral
        </p>
        <a href={linkedInUrl} target="_blank" rel="noopener noreferrer" className="mt-4 inline-block">
        <button className="border text-black hover:bg-white hover:text-white font-bold py-2 px-4 rounded-full transition-colors duration-200" style={{ borderColor: '#001369', color: '#001369' }}>
          Conectar
        </button>

        </a>
      </div>
    </div>
  );
};

export default ProfileCard;
