// Legal.tsx
import React from 'react';
import './Legal.css';
import LegalImg from '../../assets/LegalImg.webp';
import Accordion from './Accordion'; // Asegúrate de que la ruta de importación sea correcta
import ContactUsForm from '../contactUs/form/contactUsForm';
import { cn } from '../../lib/utils';

interface LegalProps {
  id: string;
}

const Legal: React.FC<LegalProps> = ({ id }) => {
  return (
    <>
      <div className="relative   h-[60vh] overflow-hidden after:absolute after:w-full after:h-full after:left-0 after:bottom-0 after:bg-secundarySectionHome transition-all duration-500 opacity-100 z-10">
        <div className="relative   h-[60vh] overflow-hidden after:absolute after:w-full after:h-full after:left-0 after:bottom-0 after:bg-secundarySectionHome transition-all duration-500 opacity-100 z-10">
            <img src={LegalImg} alt="Background" className="object-cover w-full h-full"></img>
              <div className="space-y-4 absolute left-[10%] right-[10%] top-[40%] w-[100%] max-w-[80%] z-10">
                  <p className={cn("uppercase tracking-[10px] text-center text-color-custom translate-y-[30px] blur-[20px] opacity-0 animate-show-content")}>COMPROMISO NORMATIVO</p>
                  <h2 className={cn("text-4xl md:text-5xl lg:text-6xl m-0 text-center text-color-custom font-bold translate-y-2 md:translate-y-[20px] blur-sm md:blur-[20px] opacity-0 animation-delay-4 animate-show-content")}>Marco Legal</h2>
                  <p className={cn('text-xs md:text-sm lg:text-base text-color-custom text-center mt-2 md:mt-4 blur-sm md:blur-[20px] opacity-0 animation-delay-5 animate-show-content')}>Asegure el cumplimiento de las normativas en Seguridad y Salud en el Trabajo. Nosotros le guiamos para que opere con serenidad y seguridad.</p>
              </div>


            </div>
      </div>
  
   
<div className="container mx-auto px-4 sm:px-6 lg:px-8 my-8 sm:my-12 lg:my-12">
  <h2 className="text-1.5xl sm:text-2xl md:text-3xl text-center font-bold mb-4 sm:mb-6 lg:mb-6 ">Marco Regulatorio Nacional sobre Seguridad y Salud en el Trabajo</h2>
  <Accordion title="Leyes">
          <ul className="list-disc pl-4">
            {/* Los elementos de la lista deben ser reemplazados con los enlaces reales */}
            <li><a href="http://www.impo.com.uy/bases/leyes/16074-1989" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 16.074</a>
            <span className="text-gray-800"> Cobertura de Seguros para Accidentes Laborales y Enfermedades Profesionales</span>
            </li>
            <li><a href="http://www.impo.com.uy/bases/leyes/18172-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 18.172 </a>
            <span className="text-gray-800"> - Rendición de Cuentas 2006 - Agrega inciso a Art. 60 de Ley 16.074</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/18099-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 18.099 </a>
            <span className="text-gray-800"> - Protección de los Derechos Laborales</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/18251-2008" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 18.251 </a>
            <span className="text-gray-800"> - Obligaciones Laborales en la Descentralización Empresarial</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/19196-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 19.196 </a>
            <span className="text-gray-800"> - Implicaciones Penales para Empresas</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/15965-1988" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 15.965 </a>
            <span className="text-gray-800"> Aprobación CIT en Seguridad y Salud Laboral N° 148, 155, 161</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/17828-2004" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 17.828 </a>
            <span className="text-gray-800"> - Aprobación CIT de Trabajo N° 184 – Seguridad y Salud en Agricultura</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/17584-2002" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 17.584 </a>
            <span className="text-gray-800"> - Aprobación CIT de Trabajo N° 167 – Seguridad y Salud en Construcción</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/14116-1973" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 14.116 </a>
            <span className="text-gray-800"> - Aprobación CIT de Trabajo N° 121 – Gestión de Enfermedades Profesionales</span></li>
            <li><a href="http://www.impo.com.uy/bases/decretos-ley/14976-1979" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 14.976 </a>
            <span className="text-gray-800"> - Aprobación CIT de Trabajo N° 139 – Manejo de Sustancias o Agentes Cancerígenos</span>
            </li>
            <li><a href="http://www.impo.com.uy/bases/leyes/17793-2004" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 17.793 </a>
            <span className="text-gray-800"> - Aprobación del Convenio Marco de la OMS para el Control del Tabaco</span></li>
            <li><a href="http://www.impo.com.uy/bases/decretos-ley/14785-1978"className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 14.785</a>
            <span className="text-gray-800"> - Normativa para Trabajadores del Sector Rural</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/9697-1937" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 9.697  </a>
            <span className="text-gray-800"> - Regulación del Carnet de Salud</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/17774-2004" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 17.774</a>
            <span className="text-gray-800"> - Evaluación de Niveles de Plomo en Sangre</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/18360-2008" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 18.360 </a>
            <span className="text-gray-800"> - Regulaciones para el Uso de Desfibriladores Externos Automáticos (DEA)</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/19172-2013" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 19.172 </a>
            <span className="text-gray-800"> - Gestión y Normativa sobre el Cannabis</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/17852-2004" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 17.852</a>
            <span className="text-gray-800"> - Normativas sobre Contaminación Sonora</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/15896-1987" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 15.896</a>
            <span className="text-gray-800"> - Medidas para la Prevención y Control de Incendios</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/18191-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 18.191</a>
            <span className="text-gray-800"> - Tránsito y Seguridad Vial Nacional</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/19061-2013" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 19.061</a>
            <span className="text-gray-800"> -Disposiciones Adicionales a la Ley N° 18.191</span></li>
            <li><a href="http://www.impo.com.uy/bases/leyes/18156-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley 18.156</a>
            <span className="text-gray-800"> - Día Mundial de la Seguridad y Salud en el Trabajo</span></li>
            {/* ... Agregar el resto de los elementos ... */}
          </ul>
  </Accordion>
  <Accordion title="Decretos">
  <ul>
    <li><a href="https://www.impo.com.uy/bases/decretos/183-1982" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 183/982</a>
    <span className="text-gray-800"> - Prevención de riesgos ocupacionales por agentes Cancerígenos</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/268-2005" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 268/005</a>
    <span className="text-gray-800"> - Espacios sin humo de tabaco</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/40-2006" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 40/006</a>
    <span className="text-gray-800"> - Prohibición de fumar en recintos cerrados – públicos y lugares de trabajo</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/216-2012" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 216/012</a>
    <span className="text-gray-800"> - Estatuto del empleado rural. Regulación. Cambios</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/651-1990" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 651/990</a>
    <span className="text-gray-800"> - Certificado de salud básico, necesario y obligatorio</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/571-2006" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 571/006</a>
    <span className="text-gray-800"> - Carnet de salud básico para mujeres</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/330-2009" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 330/009</a>
    <span className="text-gray-800"> - Desfibriladores externos automáticos. Regulación.</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/260-2013" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 260/013</a>
    <span className="text-gray-800"> - Protección contra fuegos</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/547-2009" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 547/009</a>
    <span className="text-gray-800"> - Entrenamiento en el uso de materiales de defensa contra emergencias</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/81-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 81/014</a>
    <span className="text-gray-800"> - Normativas de tráfico y seguridad vial</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/173-2013" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 173/013</a>
    <span className="text-gray-800"> - Tránsito de maquinaria agrícola en rutas nacionales</span></li>
    {/* ... Agregar el resto de los elementos ... */}
</ul>

</Accordion>
  <h2 className="text-1.5xl sm:text-2xl md:text-3xl text-center font-bold mb-4 sm:mb-6 lg:mb-6 mt-10">Prevención de Riesgos Laborales - <a href="https://www.impo.com.uy/bases/leyes/5032-1914" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Normativa N° 5032</a></h2>
  <Accordion title="Sector Industrial, Comercial o de Servicios">
  <ul className="list-disc pl-4">
    {/* Los elementos de la lista deben ser reemplazados con los enlaces reales */}
    <li><a href="https://www.impo.com.uy/bases/decretos/406-1988" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 406/988</a>
    <span className="text-gray-800"> - Normas de seguridad e higiene en el trabajo. Seguridad ocupacional</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/291-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 291/007</a>
    <span className="text-gray-800"> - Regulación del convenio internacional del trabajo n° 155 sobre prevención y protección contra riesgos derivados de cualquier actividad</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/127-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 127/014</a>
    <span className="text-gray-800"> - Regulación del convenio internacional de trabajo 161 relativo a los servicios de prevención y salud en el trabajo ratificado por la ley 15.965</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/197-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 197/014</a>
    <span className="text-gray-800"> - Implementación obligatoria de los servicios de prevención y salud en el trabajo en las instituciones de asistencia médica colectiva, mutualistas y cooperativas médicas</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/423-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 423/007</a>
    <span className="text-gray-800"> - Restricción del peso de bolsas de cemento, harina, azúcar, arroz, productos agrícolas, sujetos al transporte manual de cargas</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/499-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 499/007</a>
    <span className="text-gray-800"> - Modificación del reglamento de seguridad e higiene en el trabajo y regulación de la actividad de las industrias navales</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/474-2009" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 474/009</a>
    <span className="text-gray-800"> - Modificación del reglamento de seguridad e higiene en el trabajo</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/147-2012" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 147/012</a>
    <span className="text-gray-800"> - Regulación de las condiciones y ambiente laboral en los centros de atención telefónica, procesamiento de datos y telecentros</span></li>
    {/* ... Agregar el resto de los elementos ... */}
</ul>
</Accordion>

<Accordion title="Construcción">
<ul className="list-disc pl-4">
    {/* Los elementos de la lista deben ser reemplazados con los enlaces reales */}
    <li><a href="https://www.impo.com.uy/bases/decretos/125-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 125/014</a>
    <span className="text-gray-800"> - Normativas del acuerdo internacional de trabajo n° 167, sobre seguridad y salud en la construcción aprobado por la ley 17.584</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/283-1996" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 283/996</a>
    <span className="text-gray-800"> - Registro de proyectos y presentación del estudio y plan de seguridad e higiene</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/481-2009" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 481/009</a>
    <span className="text-gray-800"> - Regulación de la ley nº 18.362 en lo referente a la implementación y funcionamiento del «registro nacional de obras de construcción y su trazabilidad»</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/423-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 423/007</a>
    <span className="text-gray-800"> - Restricción del peso de bolsas de cemento, harina, azúcar, arroz, productos agrícolas, sujetos al transporte manual de cargas</span></li>
    {/* ... Agregar el resto de los elementos ... */}
</ul>

</Accordion>

  <Accordion title=" Sector Forestal">
  <ul className="list-disc pl-4">
    {/* Los elementos de la lista deben ser reemplazados con los enlaces reales */}
    <li><a href="https://www.impo.com.uy/bases/decretos/372-1999" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 372/999</a>
    <span className="text-gray-800"> - Normativas de seguridad, higiene y salud ocupacional en el ámbito forestal</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/291-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 291/007</a>
    <span className="text-gray-800"> - Regulación del convenio internacional del trabajo n° 155 sobre prevención y protección contra riesgos derivados de cualquier actividad</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/127-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 127/014</a>
    <span className="text-gray-800"> - Regulación del convenio internacional de trabajo 161 relativo a los servicios de prevención y salud en el trabajo ratificado por la ley 15.965</span></li>
</ul>

</Accordion>

<Accordion title="Industria Química">
<ul className="list-disc pl-4">
    {/* Los elementos de la lista deben ser reemplazados con los enlaces reales */}
    <li><a href="https://www.impo.com.uy/bases/decretos/307-2009" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 307/009</a>
    <span className="text-gray-800"> - Regulación para la protección de la seguridad y la salud de los trabajadores contra los riesgos relacionados con los agentes químicos durante el trabajo</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/306-2005" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 306/005</a>
    <span className="text-gray-800"> - Regulación para la prevención y protección contra los riesgos derivados de la industria química</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/346-2011" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 346/011</a>
    <span className="text-gray-800"> - Modificación del decreto 307/009. Etiquetado de productos químicos. Sistema globalmente armonizado</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/128-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 128/014</a>
    <span className="text-gray-800"> - Regulación del convenio internacional de trabajo 161, relativo a los servicios de prevención y salud en el trabajo, para la industria química, grupo 7, subgrupo 02, ratificado por ley 15.965</span></li>
    {/* ... Agregar el resto de los elementos ... */}
</ul>

</Accordion>

<Accordion title="Medidas Preventivas de Aplicación General">
<ul className="list-disc pl-4">
    {/* Los elementos de la lista deben ser reemplazados con los enlaces reales */}
    <li><a href="https://www.impo.com.uy/bases/decretos/103-1996" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 103/996</a>
    <span className="text-gray-800"> - Normativas sobre salud, seguridad e higiene laboral. Normas técnicas UNIT</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/143-2012" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 143/012</a>
    <span className="text-gray-800"> - Establecimiento de medidas para prevenir las consecuencias negativas en la salud de los trabajadores debido a la intensidad del ruido</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/210-2011" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 210/011</a>
    <span className="text-gray-800"> - Modificación del decreto 103/996. Normas UNIT relacionadas con la seguridad e higiene laboral</span></li>
    <li><a href="https://www.gub.uy/ministerio-salud-publica/" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ord. 145/009</a>
    <span className="text-gray-800"> - Supervisión sanitaria de trabajadores expuestos a factores de riesgo laboral (Ordenanza del MSP)</span></li>
    {/* ... Agregar el resto de los elementos ... */}
</ul>

</Accordion>

<Accordion title="Gestiones Administrativas de Aplicación General">
<ul className="list-disc pl-4">
    <li><a href="https://www.impo.com.uy/bases/decretos/680-1977" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 680/977</a>
    <span className="text-gray-800"> - Inspección y control laboral, seguridad social y protección laboral</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/186-2004" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 186/004</a>
    <span className="text-gray-800"> - Normativas sobre infracciones en el ámbito laboral</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/108-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 108/007</a>
    <span className="text-gray-800"> - Actualización de regulaciones sobre gestión de documentos laborales</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/41-2012" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 41/012</a>
    <span className="text-gray-800"> - Revisión del código nacional de enfermedades y eventos sanitarios notificables</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/169-2004" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 169/004</a>
    <span className="text-gray-800"> - Modificaciones al código nacional de enfermedades y eventos sanitarios notificables</span></li>
    {/* ... Agregar el resto de los elementos ... */}
</ul>


</Accordion>

<Accordion title="Actividades agropecuarias">
<ul className="list-disc pl-4">
    {/* Los elementos de la lista deben ser reemplazados con los enlaces reales */}
    <li><a href="https://www.impo.com.uy/bases/decretos/321-2009" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 321/009</a>
    <span className="text-gray-800"> - Reglamentación del convenio internacional de trabajo Nº 184 sobre seguridad y salud en la agricultura</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/509-2009" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 509/009</a>
    <span className="text-gray-800"> - Modificaciones a la reglamentación del convenio internacional de trabajo Nº 184 sobre seguridad y salud en la agricultura</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/291-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 291/007</a>
    <span className="text-gray-800"> - Reglamentación del convenio internacional del trabajo N° 155 sobre prevención y protección contra riesgos derivados de cualquier actividad</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/127-2014" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 127/014</a>
    <span className="text-gray-800"> - Reglamentación del convenio internacional de trabajo 161 relativo a los servicios de prevención y salud en el trabajo ratificado por la ley 15.965</span></li>
    <li><a href="https://www.impo.com.uy/bases/decretos/423-2007" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Dec. 423/007</a>
    <span className="text-gray-800"> - Limitación del peso de bolsas de portland, harina, azúcar, arroz, productos de huerta, objeto del transporte manual de cargas</span></li>
    <li><a href="https://www.impo.com.uy/bases/leyes/9991-1940" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley No. 9.991 de 20/12/1940</a>
    <span className="text-gray-800"> - Aspectos relacionados con el cultivo y comercialización de arroz</span></li>
    <li><a href="https://www.impo.com.uy/bases/leyes/10471-1944" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley No. 10.471 de 03/03/1944</a>
    <span className="text-gray-800"> - Normativa sobre la gestión y conservación de los recursos forestales</span></li>
    <li><a href="https://www.impo.com.uy/bases/leyes/13130-1963" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley No 13.130 de 13/06/1963 y No. 13.389 de 18/11/1965</a>
    <span className="text-gray-800"> - Normativa sobre el funcionamiento y condiciones de los tambos</span></li>
    <li><a href="https://www.impo.com.uy/bases/leyes/11718-1951" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Ley No 11.718 de 27/09/1951</a>
    <span className="text-gray-800"> - Normativa para la actividad de esquila en la industria textil</span></li>
    {/* ... Agregar el resto de los elementos ... */}
</ul>

</Accordion>

</div>

    </>
  );
}

export default Legal;





