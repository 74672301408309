import { cn } from "../../lib/utils";
import './ContactUs.css'
export function ContactInfo () {
    return (
      <div className="text-[#001369] bg-gray-200 p-9">
        <div className="border-b-4 border-[var(--terceary-color)] pb-2 mb-4">
          <h1 className="textTitle font-bold">¿NECESITA ASESORAMIENTO?</h1>
          <p className="text-2xl">CONTÁCTENOS:</p>
        </div>
        <p className="textTitle text-5xl mb-4">(+598) 98 945 569</p>
        <p className="text-base">
        En Segutra, responderemos con atención a sus consultas y nos mantenemos a su disposición para agendar una entrevista y examinar en detalle las necesidades de su empresa.
        </p>
        <ul className="list-disc addressInfo pl-5 mt-4">
          {/* <li>DIRECCIÓN: Cno Cibils 4623, Montevideo, Uruguay</li> */}
          <li>TELÉFONO: 098 945 569</li>
          <li>E-MAIL: segutra.prevencion@gmail.com</li>
        </ul>
        <div className={cn("mt-4 z-11")}>
          <a href="https://www.linkedin.com/in/segutra-prevencion-545a7a2b4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><i style={{fontSize:30}} className="ml-2 ri-linkedin-box-fill"></i></a>
          <a href="https://www.instagram.com/segutra.prevencion?igsh=MW44MXdlYXRkbjJyYg=="><i style={{fontSize:30}} className="ml-2 ri-instagram-line"></i></a>
          <a href="https://www.facebook.com/segutra.prevencion?mibextid=LQQJ4d"><i style={{fontSize:30}} className="ml-2 ri-facebook-line"></i></a>
        </div>
      </div>
    );
  };