import { Link } from 'react-router-dom';
import { cn } from '../../../lib/utils';
import './SecundarySection.css'
import { useActiveLink } from '../../../header/Header';
import home2 from './../../../assets/home2.jpeg';
import home1 from './../../../assets/home1.jpeg';

interface SecundarySectionProps {
}
export const SecundarySection = ({}: SecundarySectionProps) => {
    return (
    <div className="margin-custom-secundary container mx-auto px-4 my-12 flex flex-wrap items-stretch justify-center">
        <div className="md:flex md:w-3/4 lg:w-5/6 xl:w-4/5 2xl:w-3/4 justify-between space-x-4">
            <div className="flex-1 px-4 mb-8 md:mb-0">
                <img src={home2} alt="Image" className='object-cover img-secundary'></img>
            </div>
            <div className="flex-1 px-4 md:mt-12"> {/* Ajuste aplicado aquí */}
                <p className={cn("uppercase tracking-[10px]  translate-y-[30px] blur-[20px] opacity-0 animate-show-content")}>Nosotros</p>
                <h2 className={cn("text-6xl lg:text-5xl m-0  font-bold translate-y-[30px] blur-[20px] opacity-0 animation-delay-4 animate-show-content")}>SEGUTRA</h2>
                <p className={cn('text-200  translate-y-[30px] blur-[20px] opacity-0 animation-delay-5 animate-show-content')}>Somos un equipo multidisciplinario de profesionales que asesoramos a empresas, desempeñándonos con responsabilidad, compromiso, dedicación y ética, asegurando confidencialidad y fidelidad con nuestros clientes, asegurando procesos de mejora continua.</p>
            </div>
        </div>
    </div>
    
    // <li className={cn('absolute inset-0 overflow-hidden after:absolute after:w-full after:h-full after:left-0 after:bottom-0  transition-all duration-500 opacity-100 z-10')}>
    //      <div className="space-y-4 absolute ra left-[10%] top-[20%] w-[700px] max-w-[80%] z-10">
    //         <img src={home2} alt="Image" className='object-cover img-secundary'></img>
    //     </div>
    //     <div className="space-y-4 absolute right-[10%] top-[35%] w-[500px] max-w-[80%] z-10">
    //         <p className={cn("uppercase tracking-[10px]  translate-y-[30px] blur-[20px] opacity-0 animate-show-content")}>Nosotros</p>
    //         <h2 className={cn("text-6xl lg:text-5xl m-0  font-bold translate-y-[30px] blur-[20px] opacity-0 animation-delay-4 animate-show-content")}>SEGUTRA</h2>
    //         <p className={cn('text-200  translate-y-[30px] blur-[20px] opacity-0 animation-delay-5 animate-show-content')}>Somos un equipo multidisciplinario de profesionales que asesoramos a empresas, desempeñándonos con responsabilidad, compromiso, dedicación y ética, asegurando confidencialidad y fidelidad con nuestros clientes, asegurando procesos de mejora continua.</p>
    //     </div> 
    // </li>
    );
}