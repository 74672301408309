import { useState } from "react";
import { cn } from "../../../lib/utils";
import "./contactUsForm.css";
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";
import { useActiveLink } from "../../../header/Header";


function ContactForm() {
    const navigate = useNavigate();
    const { setLink } = useActiveLink();
    const [formData, setFormData] = useState({
        email: '',
        message: '',
        name: '',
        lastname: '',
        phone: ''
      });
    
      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        emailjs.send('service_ndw9orl', 'template_ka1btjk', formData, '_vKOzINJby8YCr5ry')
          .then((result) => {
            console.log(result.text);
            setLink('/home/refresh');
            navigate('/home');
            // Aquí puedes manejar la lógica post-envío, como mostrar un mensaje de éxito
          }, (error) => {
            console.log(error.text);
            // Aquí puedes manejar errores
          });
      };
    return (
      <div className="flex flex-col items-center top-[30%] transparent">
      <div className="w-full px-8 py-6 mt-4 text-left bg-gray-200 shadow-lg">
          <h3 className="text-2xl font-bold style={{ color: '#001369' }} text-center">CONTACTO</h3>
          <form onSubmit={sendEmail} className="mt-6">
              <div className="flex gap-4 mb-4">
                  <div className="flex-1">
                      <label className="block style={{ color: '#001369' }}" htmlFor="name">Nombre</label>
                      <input type="text" placeholder="Nombre" id="name" name="name" value={formData.name} onChange={handleChange}
                             className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                      />
                  </div>
                  <div className="flex-1">
                      <label className="block style={{ color: '#001369' }}" htmlFor="lastname">Apellido</label>
                      <input type="text" placeholder="Apellido" id="lastname" name="lastname" value={formData.lastname} onChange={handleChange}
                             className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                      />
                  </div>
              </div>
              <div className="flex gap-4 mb-4">
                  <div className="flex-1">
                      <label className="block style={{ color: '#001369' }}" htmlFor="phone">Teléfono</label>
                      <input type="tel" placeholder="Teléfono" id="phone" name="phone" value={formData.phone} onChange={handleChange}
                             className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                      />
                  </div>
                  <div className="flex-1">
                      <label className="block style={{ color: '#001369' }}" htmlFor="email">Email</label>
                      <input type="email" placeholder="Email" id="email" name="email" value={formData.email} onChange={handleChange}
                             className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                      />
                  </div>
              </div>
              <div className="mb-4">
                  <label className="block style={{ color: '#001369' }}" htmlFor="message">Mensaje</label>
                  <textarea placeholder="Escribe tu mensaje aquí" id="message" name="message" value={formData.message} onChange={handleChange}
                            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"></textarea>
              </div>
              <div className="flex justify-center mt-6">
                <input type="submit" value="Enviar" className={cn("px-10 py-2 text-white backColorButton border rounded-md hover:bg-[var(--forty-color)]")}>
                </input>
              </div>

          </form>
      </div>
  </div>
    );
  }
  
  export default ContactForm;
  