import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[var(--terceary-color)] text-white p-6 text-lg">
      <div className="container mx-auto flex flex-col items-center md:flex-row md:justify-between text-center md:text-left">
        <div className="mb-6 md:mb-0">
          <a href="#" className="text-white hover:text-gray-300">© 2024 Company Name. All rights reserved.</a>
        </div>
        <div className="flex justify-center space-x-4 mb-6 md:mb-0">
          <a href="https://www.instagram.com/segutra.prevencion?igsh=MW44MXdlYXRkbjJyYg==" className="text-white hover:text-gray-300">
            <i style={{fontSize:30}} className="ri-instagram-line"></i>
          </a>
          <a href="https://www.facebook.com/segutra.prevencion?mibextid=LQQJ4d" className="text-white hover:text-gray-300">
            <i style={{fontSize:30}} className="ri-facebook-line"></i>
          </a>
          <a href="https://www.linkedin.com/in/segutra-prevencion-545a7a2b4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className="text-white hover:text-gray-300">
            <i style={{fontSize:30}} className="ri-linkedin-line"></i>
          </a>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <span className="text-white hidden md:inline">|</span>
          <span className="text-white">(+598) 98 945 569</span>
          <span className="text-white hidden md:inline">|</span>
          <a href="mailto:segutra.prevencion@gmail.com" className="text-white hover:text-gray-300">segutra.prevencion@gmail.com</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;