import React from 'react';
// Importa la imagen que deseas usar
import C1 from '../../assets/C1.webp'; // Ajusta la ruta según tu estructura de archivos
import { cn } from '../../lib/utils';
import './Services.css';

const ConstructionServices: React.FC = () => {
  return (
    // <div className="bg-white m-4 overflow-hidden">
    //   {/* Título centrado */}
    //   <div className="text-center py-4">
    //     <h2 className="text-2xl font-bold text-[#001369]">Construcción</h2>
    //   </div>
      
    //   {/* Contenedor de Imagen y Texto */}
    //   <div className="flex flex-wrap justify-center items-center md:items-stretch">
    //     {/* Bloque de Imagen */}
    //     <div className="md:w-1/2 flex justify-center md:justify-end">
    //       <img src={C1} alt="Construcción" className="object-cover max-h-[100%] md:max-w-[100%]" />
    //     </div>
        
    //     {/* Bloque de Información */}
    //     <div className="md:w-1/2 p-6 flex flex-col justify-center">
    //       <ul className="list-disc space-y-2 pl-5 text-gray-700">
    //         <li>Estudio y Plan de Seguridad e Higiene</li>
    //         <li>Inducción de Seguridad e higiene con validez de 2 años</li>
    //         <li>Registro y gestión de Libro de obra</li>
    //         <li>Visitas e inspecciones en obra</li>
    //         <li>Elaboración de Memoria de andamios, Plan de excavación y demolición</li>
    //         <li>Elaboración de Permisos de trabajo y Análisis de trabajo seguro (AST)</li>
    //         <li>Evaluaciones de riesgos</li>
    //         <li>Registro de entrega e inspección de EPP y EPC</li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
    <div className="margin-custom-secundary container mx-auto px-4 my-12 flex flex-wrap items-stretch justify-center">
    <div className="md:flex md:w-3/4 lg:w-5/6 xl:w-4/5 2xl:w-3/4 justify-between space-x-4">
        <div className="flex-1 px-4 mb-8 md:mb-0">
            <img src={C1} alt="Image" className='object-cover img-secundary'></img>
        </div>
        <div className="flex-1 px-4 md:mt-2"> {/* Ajuste aplicado aquí */}
            <p className={cn("uppercase tracking-[10px] subtitle  translate-y-[30px] blur-[20px] opacity-0 animate-show-content")}>Más destacado</p>
            <h2 className={cn("text-6xl lg:text-5xl m-0 title font-bold translate-y-[30px] blur-[20px] opacity-0 animation-delay-4 animate-show-content")}>Construcción</h2>
            <p className={cn('text-200 mt-10 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Estudio y Plan de Seguridad e Higiene</p>
             <p className={cn('text-200 mt-2 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Inducción de Seguridad e higiene con validez de 2 años</p>
             <p className={cn('text-200 mt-2 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Registro y gestión de Libro de obra</p>
             <p className={cn('text-200 mt-2 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Visitas e inspecciones en obra</p>
             <p className={cn('text-200 mt-2 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Elaboración de Memoria de andamios, Plan de excavación y demolición</p>
             <p className={cn('text-200 mt-2 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Elaboración de Permisos de trabajo y Análisis de trabajo seguro (AST)</p>
             <p className={cn('text-200 mt-2 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Evaluaciones de riesgos</p>
             <p className={cn('text-200 mt-2 translate-y-[30px] textP blur-[20px] opacity-0 animation-delay-5 animate-show-content')}> · Registro de entrega e inspección de EPP y EPC</p>
        
        
        </div>
    </div>
</div>
  );
};

export default ConstructionServices;
