import React from 'react';
import { cn } from '../../lib/utils';


interface WhatsAppButtonProps {
    phoneNumber: string;
    message: string;
}

const WhatsAppButton = ({ phoneNumber, message } : WhatsAppButtonProps) => {
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" style={{zIndex:11}}
       className={cn("fixed bottom-5 z-11 right-5 bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg flex items-center justify-center w-14 h-14")}
       aria-label="Contact us on WhatsApp">
        <i style={{fontSize:30}} className={cn("ri-whatsapp-line")}></i>
    </a>
  );
};

export default WhatsAppButton;
